import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import '../stylesheets/application'
import 'materialize-css/dist/js/materialize'
require("jquery")
require("@nathanvda/cocoon")

Rails.start()
ActiveStorage.start()

$(document).ready(function(){

    $('.datepicker').datepicker({
        yearRange: [1930,2030],
        autoClose: true,
        showClearBtn: true,
        format: 'yyyy-mm-dd'
    });

    $('.sidenav').sidenav();

    $('.chips').chips();
    
    $('.collapsible').collapsible();
    
    $('.modal').modal();
    
    $('.modal_inspection').modal({
      dismissible: false,
      opacity: 1,
      startingTop: '0%',
      endingTop: '0%'
    });

    $('select').formSelect();

    $('.dropdown-trigger').dropdown();

    $('.timepicker').timepicker({
        autoClose: true,
        twelveHour: false
    });

    
    var link = document.getElementById("additembutton");

    document.onkeydown = function (e) {
        if (e.keyCode == 107){
            link.click();
        }
    };

    $( document ).on( 'focus', ':input', function(){
      $( this ).attr( 'autocomplete', 'off' );
    });


    (function() {
        window.requestAnimFrame = (function(callback) {
          return window.requestAnimationFrame ||
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame ||
            window.oRequestAnimationFrame ||
            window.msRequestAnimaitonFrame ||
            function(callback) {
              window.setTimeout(callback, 1000 / 60);
            };
        })();
      
        var canvas = document.getElementById("sig-canvas");
        var ctx = canvas.getContext("2d");
        ctx.strokeStyle = "#0394c9";
        ctx.lineWidth = 4;
      
        var drawing = false;
        var mousePos = {
          x: 0,
          y: 0
        };
        var lastPos = mousePos;
      
        canvas.addEventListener("mousedown", function(e) {
          drawing = true;
          lastPos = getMousePos(canvas, e);
        }, false);
      
        canvas.addEventListener("mouseup", function(e) {
          drawing = false;
        }, false);
      
        canvas.addEventListener("mousemove", function(e) {
          mousePos = getMousePos(canvas, e);
        }, false);
      
        // Add touch event support for mobile
        canvas.addEventListener("touchstart", function(e) {
      
        }, false);
      
        canvas.addEventListener("touchmove", function(e) {
          var touch = e.touches[0];
          var me = new MouseEvent("mousemove", {
            clientX: touch.clientX,
            clientY: touch.clientY
          });
          canvas.dispatchEvent(me);
        }, false);
      
        canvas.addEventListener("touchstart", function(e) {
          mousePos = getTouchPos(canvas, e);
          var touch = e.touches[0];
          var me = new MouseEvent("mousedown", {
            clientX: touch.clientX,
            clientY: touch.clientY
          });
          canvas.dispatchEvent(me);
        }, false);
      
        canvas.addEventListener("touchend", function(e) {
          var me = new MouseEvent("mouseup", {});
          canvas.dispatchEvent(me);
        }, false);
      
        function getMousePos(canvasDom, mouseEvent) {
          var rect = canvasDom.getBoundingClientRect();
          return {
            x: mouseEvent.clientX - rect.left,
            y: mouseEvent.clientY - rect.top
          }
        }
      
        function getTouchPos(canvasDom, touchEvent) {
          var rect = canvasDom.getBoundingClientRect();
          return {
            x: touchEvent.touches[0].clientX - rect.left,
            y: touchEvent.touches[0].clientY - rect.top
          }
        }
      
        function renderCanvas() {
          if (drawing) {
            ctx.moveTo(lastPos.x, lastPos.y);
            ctx.lineTo(mousePos.x, mousePos.y);
            ctx.stroke();
            lastPos = mousePos;
          }
        }
      
        // Prevent scrolling when touching the canvas
        document.body.addEventListener("touchstart", function(e) {
          if (e.target == canvas) {
            e.preventDefault();
          }
        }, false);
        document.body.addEventListener("touchend", function(e) {
          if (e.target == canvas) {
            e.preventDefault();
          }
        }, false);
        document.body.addEventListener("touchmove", function(e) {
          if (e.target == canvas) {
            e.preventDefault();
          }
        }, false);
      
        (function drawLoop() {
          requestAnimFrame(drawLoop);
          renderCanvas();
        })();
      
        // Set up the UI
        var sigImage = document.querySelector('[id*="_signature_string"]');
        canvas.addEventListener("mouseup", function(e) {
          var dataUrl = canvas.toDataURL();
          sigImage.setAttribute("value", dataUrl);
        }, false);
        canvas.addEventListener("touchend", function(e) {
          var dataUrl = canvas.toDataURL();
          sigImage.setAttribute("value", dataUrl);
        }, false);
      
      })();
    
});